import { MotionProps, Variant } from 'framer-motion';

type props = 'initial' | 'animate' | 'exit';
type CommomAnimation = Required<Pick<MotionProps, props>>;
type CombineAnimationsParam = CommomAnimation[];

export const animMerge = (
  ...animations: CombineAnimationsParam
): CommomAnimation => {
  let initial: Variant = {};
  let animate: Variant = {};
  let exit: Variant = {};

  animations.forEach(animation => {
    Object.keys(animation).forEach(key => {
      initial = { ...initial, ...(animation.initial as Variant) };
      animate = { ...animate, ...(animation.animate as Variant) };
      exit = { ...exit, ...(animation.exit as Variant) };
    });
  });

  return {
    initial,
    animate,
    exit,
  } as CommomAnimation;
};

export const fadeIn: CommomAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const slideHorizontal = (x: number = -100): CommomAnimation => ({
  initial: { x },
  animate: { x: 0 },
  exit: { x },
});

export const slideVertical = (y: number = 100): CommomAnimation => ({
  initial: { y },
  animate: { y: 0 },
  exit: { y },
});

export const scale: CommomAnimation = {
  initial: { scale: 0 },
  animate: { scale: 1 },
  exit: { scale: 0 },
};

export const spin: CommomAnimation = {
  initial: { rotate: 0 },
  animate: { rotate: 360 },
  exit: { rotate: 0 },
};

export const accordion: CommomAnimation = {
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: 'auto' },
  exit: { opacity: 0, height: 0 },
};

export const fadeInVariants = {
  show: {
    transition: { duration: 0.25 },
    opacity: 1,
  },
  hidden: {
    transition: { duration: 0.25 },
    opacity: 0,
    height: 0,
  },
};

export const accordionX = (open?: boolean) => ({
  initial: { width: open ? 'auto' : '0rem' },
  animate: { width: open ? 'auto' : '0rem' },
  exit: { width: open ? 'auto' : '0rem' },
  transition: { duration: 0.25 },
});

export const accordionY = (open?: boolean) => ({
  initial: { height: open ? 'auto' : '0rem' },
  animate: { height: open ? 'auto' : '0rem' },
  exit: { height: open ? 'auto' : '0rem' },
  transition: { duration: 0.25 },
});

export const dropdown = {
  initial: { opacity: 0, scale: 0.95 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 },
  transition: { duration: 0.1 },
};

export const spring = {
  type: 'spring',
  damping: 30,
  stiffness: 400,
};
