type Transformer<T> = (block: T) => T;

export const transformer = <T>(data: T[], transformers: Transformer<T>[]) => {
  data = data.map(block => {
    return transformers.reduce((acc, transformer) => transformer(acc), block);
  });
  return data;
};

export default transformer;
