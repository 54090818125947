import { HackathonStage } from '@/models/Hackathon';
import moment from 'moment';

export function getCurrentStage(
  stages: HackathonStage[],
): HackathonStage | undefined {
  const now = moment();
  return (
    stages.find(stage => {
      const startDate = moment(stage.startDate);
      const endDate = moment(stage.endDate);
      return now.isBetween(startDate, endDate, null, '()');
    }) || undefined
  );
}

type stageStatus = 'future' | 'complete' | 'progress';

export function getStageStatus(stage: HackathonStage): stageStatus {
  const now = moment();
  const startDate = moment(stage.startDate);
  const endDate = moment(stage.endDate);

  if (now.isBefore(startDate)) {
    return 'future';
  }
  if (now.isAfter(endDate)) {
    return 'complete';
  }
  return 'progress';
}
