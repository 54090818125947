import { ProjectFields } from '@/components/projects/form/ProjectForm';
import { CreateProject } from '@/data/services/projectServices';
import Flag from '@/models/Flag';
import Hackathon from '@/models/Hackathon';
import { Project } from '@/models/Project';

export const generateFlagsPayload = (
  flagFields: Partial<Pick<ProjectFields, 'courseFlag' | 'isHackathon'>>,
  hackathon?: Hackathon,
  flags?: Flag[],
) => {
  const originalFlagsIds = flags?.map(flag => flag.id) ?? [];
  let flagsIds: number[] = [];
  if (flagFields.courseFlag) flagsIds.push(flagFields.courseFlag.id);
  if (flagFields.isHackathon && hackathon) flagsIds.push(hackathon?.flagId);
  flagsIds = flagsIds.concat(originalFlagsIds);
  flagsIds = Array.from(new Set(flagsIds));
  flagsIds = flagsIds.sort();
  return { flagsIds };
};

function isObjectUrl(url?: string): boolean {
  return url?.startsWith('blob:') ?? false;
}

export const generateImagePayload = (
  imageFields: Partial<Pick<ProjectFields, 'image' | 'imageFile'>>,
): Pick<CreateProject, 'image' | 'imageUrl'> => {
  if (isObjectUrl(imageFields.image))
    return {
      image: imageFields.imageFile,
    };
  return {
    imageUrl: imageFields.image,
  };
};

export const adapterProjectToCreateProject = (project: Partial<Project>) => {
  const createProject: Partial<CreateProject> = {
    projectUrl: project?.projectUrl,
    title: project?.title,
    equipName: project?.equipName,
    description: project?.description,
    shared: project?.shared,
    toolId: project?.tool?.id,
    imageUrl: project?.image,
    usersIds: project?.users?.map(user => user.id),
    flagsIds: project?.flags?.map(flag => flag.id).sort(),
    teacherMentorId: project.teacherMentor?.id,
  };
  return createProject;
};
