import { isArray } from 'lodash';

export function convertToFormData<T extends Record<string, any>>(
  body: T,
  customKey?: Partial<
    Record<keyof T, (key: string, value: any, formData: FormData) => void>
  >,
): FormData {
  const formData = new FormData();

  Object.entries(body).forEach(([key, value]) => {
    if (customKey?.[key as keyof T]) {
      customKey[key as keyof T]!(key, value, formData);
    } else if (isArray(value)) {
      value.forEach(item => {
        formData.append(key, item);
      });
    } else {
      formData.set(key, value instanceof File ? value : String(value || ''));
    }
  });

  return formData;
}
